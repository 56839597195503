export default [
  {
    path: "/profile",
    name: "profile",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/self-service/profile"),
  },
  {
    path: "/payslip",
    name: "payslip",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/self-service/payslip"),
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/self-service/change-password"),
  },
  {
    path: "/leave-applications",
    name: "leave-applications",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/leave-management/leave-application"),
  },
  {
    path: "/leave-application-details/:leaveAppID",
    name: "leave-application-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/leave-management/leave-application-details"
      ),
  },
  {
    path: "/manage-leave-applications",
    name: "manage-leave-applications",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/leave-management/manage-leave-applications"
      ),
  },
  {
    path: "/manage-leave-applications/:t7",
    name: "manage-leave-applications-t7",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/leave-management/manage-leave-application"
      ),
  },
  {
    path: "/manage-leave-application/:leaveAppID",
    name: "manage-leave-application",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/leave-management/manage-leave-application"
      ),
  },
  {
    path: "/application-tracking",
    name: "application-tracking",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/application-tracking/application-tracking"
      ),
  },
  {
    path: "/application-tracking/leave/:leaveAppID",
    name: "leave-application-tracking",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/application-tracking/leave"
      ),
  },
  {
    path: "/my-notifications/:employeeId",
    name: "my-notifications",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/self-service/my-notifications"
      ),
  },
  {
    path: "/employee-leave-accruals",
    name: "employee-leave-accruals",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/employee-leave-accruals"),
  },
/*  {
    path: "/performance-development-plan",
    name: "performance-development-plan",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-development-plan"),
  },*/
  {
    path: "/add-new-performance-development-plan",
    name: "add-new-performance-development-plan",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/new-performance-development-plan"),
  },
  {
    path: "/performance-development-plan",
    name: "performance-development-plan",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-development-plan"),
  },
  {
    path: "/performance-dev-plan-details/:id",
    name: "performance-dev-plan-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-dev-plan-details"),
  },
  {
    path: "/performance-development-plan",
    name: "performance-development-plan",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-development-plan"),
  },
  {
    path: "/performance-assessment",
    name: "performance-assessment",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-measure-list"),
  },
  {
    path: "/performance-assessment/:performanceId",
    name: "performance-assessment-detail",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/performance-measure"),
  },
  {
    path: "/employee-end-of-year-performance-review/:performanceId",
    name: "employee-end-of-year-performance-review",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/employee-end-of-year-performance-review"),
  },
  {
    path: "/supervisor-end-of-year-performance-review/:performanceId",
    name: "supervisor-end-of-year-performance-review",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/supervisor-end-of-year-performance-review"),
  },
  {
    path: "/employee-leave-accruals/:year/:empId",
    name: "employee-leave-accruals-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/employee-leave-accruals-details"),
  },
  {
    path: "/leave-type/:leaveTypeId/:year/:empId",
    name: "leave-accrual-leave-type-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/self-service/employee-leave-accruals-leave-type"),
  },
];
